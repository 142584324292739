import React from 'react'
import { useTranslations } from 'next-intl'
import { NextSeo } from 'next-seo'
import { RenderClientSide } from '@nordic-web/utils/components/render-client-side'
import { BRAND } from '@/config/brand'
import { NordicApp } from '@/layouts/app'
import { Faq } from '@/pages/faq/faq'
import { MTVFaq } from '@/pages/faq/mtv-faq'
import type { Page } from '@/pages/types'

const FAQPage: Page = () => {
  const t = useTranslations()

  return (
    <>
      <NextSeo title={t('seo__faq_title')} />
      {BRAND === 'tv4' ? (
        <Faq />
      ) : (
        <RenderClientSide>
          <MTVFaq />
        </RenderClientSide>
      )}
    </>
  )
}

FAQPage.getLayout = (Page, props) => {
  return (
    <NordicApp>
      <Page {...props} />
    </NordicApp>
  )
}

export default FAQPage
