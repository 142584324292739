import React, { useCallback, useEffect, useRef, useState } from 'react'
import Bugsnag from '@bugsnag/js'
import Script from 'next/script'
import { FullPageCircleSpinner } from '@nordic-web/ui-components'
import { useOnNavigateToCurrentUrl } from '@/features/page/hooks/use-on-navigate-to-current-url'
import { useUserQuery } from '@/features/user/hooks/use-user-query'
import { useNativeWebviewStyling } from '@/hooks/use-native-webview-styling'
import { ForceHideWebviewBannersStyle } from '@/styles/force-hide-webview-banners'

const isSandboxEnvironment = false

const baseUrl = isSandboxEnvironment ? 'https://mtv--uat.sandbox.my.site.com/faq' : 'https://mtv.my.site.com/faq'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    cmp: LegitimateAny
    $Lightning?: {
      use(appName: string, callback: () => void, baseURL: string): void
      createComponent(
        componentName: string,
        attributes: Record<string, LegitimateAny>,
        domElementId: string,
        callback: (component: LegitimateAny) => void
      ): void
    }
  }
}

type LightningComponentAttributes = {
  suppliedFullName: string
  suppliedEmail: string
}

const initializeLightningComponent = (attributes: LightningComponentAttributes) => {
  if (window.$Lightning) {
    window.$Lightning.use(
      'c:FAQApp',
      () => {
        window.$Lightning?.createComponent('c:FaqComponent', attributes, 'faq-component', (cmp: LegitimateAny) => {
          window.cmp = cmp
        })
      },
      baseUrl
    )
  } else {
    Bugsnag.notify('$Lightning is not available. Ensure the Salesforce script is loaded.')
  }
}

export const MTVFaq = () => {
  const { user, isLoadingUser } = useUserQuery()
  const isInitialized = useRef(false)
  const [isScriptLoading, setIsScriptLoading] = useState(!window.$Lightning)

  // This is a workaround that makes pressing the main menu FAQ link always return you to the start page of the FAQ.
  // Its because the next js routing and the hash routing in the faq doesnt play well together
  useOnNavigateToCurrentUrl({
    onNavigate: () => setTimeout(() => window.dispatchEvent(new HashChangeEvent('hashchange')), 0),
  })

  const handleScriptLoad = useCallback(() => {
    if (isInitialized.current) return
    isInitialized.current = true
    setIsScriptLoading(false)

    initializeLightningComponent({
      suppliedFullName: `${user?.details.firstName || ''} ${user?.details.lastName || ''}`.trim(),
      suppliedEmail: user?.details.email || '',
    })
  }, [user])

  // Initialize on mount if script is already loaded
  useEffect(() => {
    if (!isLoadingUser && window.$Lightning) {
      handleScriptLoad()
    }

    return () => {
      isInitialized.current = false
      // Clean up the Lightning component
      if (window.cmp) {
        window.cmp.destroy()
        window.cmp = undefined
      }
      // Clear the DOM element
      const faqElement = document.getElementById('faq-component')
      if (faqElement) {
        faqElement.innerHTML = ''
      }
    }
  }, [isLoadingUser, handleScriptLoad])

  const scriptUrl = baseUrl + '/lightning/lightning.out.js'

  useNativeWebviewStyling()

  if (isLoadingUser) return <FullPageCircleSpinner />

  return (
    <div>
      {isScriptLoading && <FullPageCircleSpinner />}
      <Script
        id="salesforce-faq-script"
        src={scriptUrl}
        onLoad={handleScriptLoad}
        onError={() => {
          setIsScriptLoading(false)
          Bugsnag.notify('Failed to load Salesforce MTV FAQ script.')
        }}
      />
      {!isScriptLoading && (
        <>
          <ForceHideWebviewBannersStyle />
          <div id="faq-component" />
        </>
      )}
    </div>
  )
}
